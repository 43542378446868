import React, { useState, useEffect } from 'react'
import "./Footer.scss"

const Footer = () => {
  const [currentYear, setCurrentYear] = useState(null);

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  return (
    <div>
      <footer className="site-footer">
        <div className="mycontainer">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 copyright-container">
              <p className="copyright-text">
                {" "}
                &copy; {currentYear} SurePoint Technologies. SurePoint is a
                trademark registered in the U.S. Patent and Trademark Office to
                Rippe & Kingston Systems, Inc. | Version 23.02.01
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;